import {statusSorter, statusToLabel, getStatusForCard} from "../../lib/card-status-utils";
import {
  tagObjectsForUser,
  personalTags,
  projectsTags,
  projectsUsers,
  projectsDecks,
  joinAnd,
} from "../../lib/utils";
import Avatar from "../../components/Avatar";
import {usePriorityInfo} from "../../components/props";
import {api} from "../../lib/api";
import {ProjectTag, PersonalTag} from "../../components/Markdown/CardTags";
import {notificationTypesToUi, cardDiffToTypes} from "../notifications/card-diff-info";
import {moveCardsToDeck} from "../../lib/cross-project-cards-or-decks";
import {waitForResultPromise} from "../../lib/wait-for-result";
import {XRow} from "../../components/xui";
import {
  getFutureMilestonesByProject,
  getMilestoneState,
  isMilestoneValidTargetForCards,
  msToDate,
  setMsThemeColor,
  ThemedMilestoneIcon,
} from "../milestones/milestone-utils";
import {
  monthNames,
  countWorkdaysForAccount,
  dayToDate,
  superShortDate,
  dayToDateStr,
} from "../../lib/date-utils";
import {getTotalTimeForCard} from "../time-tracking/time-tracking-utils";
import {
  checkDropCards,
  getShownEffort,
  getParentCard,
  hasChildCards,
} from "../workflows/workflow-utils";
import {getSelectedProjects, getProjectOrderIdx} from "../../lib/hooks/useSelectedProjects";
import MiniDeck from "../../components/MiniDeck";
import {XText, XCol, cx, xcolors} from "@cdx/common";
import messenger from "../../lib/messenger";
import {orderInfoStyles as styles} from "./card-order.css";
import {getTotalUpvotes} from "../upvotes/upvote-utils";
import {CdxCropImgByFile} from "../../components/CdxImg";
import uiClasses from "@cdx/common/xui/ui.css";
import {performStartAction} from "../card-container/performStartAction";
import {performBulkArchiveAction} from "../card-container/performArchiveAction";
import {Col, DSIconDoc, DSIconEffort, Row, css} from "@cdx/ds";
import {hasPermissionToGuardCard} from "../../lib/permissions";
import {
  ThemedSprintIcon,
  getActiveAndFutureSprints,
  getSprintState,
  isSprintValidTargetForCards,
  sprintLabel,
} from "../milestones/sprint-utils";
import {BeastPill, getBeastLevel} from "../../components/Card/Beast";
import {AddedDuringSprintPill, getIsNewUntil} from "../../components/Card/AddedDuringSprint";

const dateToKey = (timestampInMs) => {
  const now = new Date();
  const ageInS = (now.getTime() - timestampInMs) / 1000;
  if (ageInS < (3600 * 24 * 365) / 2) {
    // less than half a year old
    if (ageInS < 3600 * 24 * 7) {
      if (ageInS < 3600 * 2) {
        return {diff: 0, label: "Within last two hours"};
      } else if (ageInS < 3600 * 24) {
        return {diff: 1, label: "Within last 24 hours"};
      } else {
        return {diff: 2, label: "Within last 7 days"};
      }
    } else {
      // older than a week
      const d = new Date(timestampInMs);
      if (d.getMonth() === now.getMonth()) {
        return {diff: 3, label: `This month`};
      } else {
        return {
          diff: 5 + (1 - d.getMonth() / 12) + now.getFullYear() - d.getFullYear(),
          label: `${monthNames[d.getMonth()].slice(0, 3)} ${d.getFullYear()}`,
        };
      }
    }
  } else {
    // older than half a year
    const d = new Date(timestampInMs);
    if (now.getFullYear() - d.getFullYear() < 2) {
      return {
        diff: 5 + (1 - d.getMonth() / 12) + now.getFullYear() - d.getFullYear(),
        label: `${monthNames[d.getMonth()].slice(0, 3)} ${d.getFullYear()}`,
      };
    } else {
      return {diff: 5 + 13 + now.getFullYear() - d.getFullYear(), label: `${d.getFullYear()}`};
    }
  }
};

const changeTypeToLabel = {
  ...Object.entries(notificationTypesToUi).reduce((m, [k, v]) => {
    m[k] = v.title;
    return m;
  }, {}),
  nonParticipatingResolvable: "Conversations started",
  none: "Cards with no open notifications",
};

const changeTypeToPriority = {
  ...Object.entries(notificationTypesToUi).reduce((m, [k, v]) => {
    m[k] = v.priority || 1;
    return m;
  }, {}),
  nonParticipatingResolvable: 2,
  none: -1,
};

const MyText = ({onLight, ...rest}) => (
  <XText size={1} lineHeight="none" color={onLight ? "gray700" : "gray100"} {...rest} />
);

const getTimeTracked = () => {
  const timeTrackedSteps = [
    {key: "No tracked time", ms: 0},
    {key: "0 - 30min", ms: 30 * 60 * 1000},
    {key: "> 30min", ms: 3600 * 1000},
    {key: "> 1h", ms: 2 * 3600 * 1000},
    {key: "> 2h", ms: 4 * 3600 * 1000},
    {key: "> 4h", ms: 8 * 3600 * 1000},
  ];

  const ttsKeyToMs = timeTrackedSteps.reduce((m, {key, ms}) => {
    m[key] = ms;
    return m;
  }, {});
  return {
    extractKey: (c) => {
      const timeInMs = getTotalTimeForCard(c);
      const found = timeTrackedSteps.find(({ms}) => timeInMs <= ms);
      return found ? found.key : "> 8h";
    },
    extractSecondarySortValue: (c) => getTotalTimeForCard(c),
    getLabel: (key, {onLight}) => <MyText onLight={onLight}>{key}</MyText>,
    toSortKey: (key) => (key in ttsKeyToMs ? ttsKeyToMs[key] : 9 * 3600 * 1000),
    secondaryOrderProps: [{prop: "timeTracked", isReversed: false}],
  };
};

const getDueDate = () => {
  const dueKeys = {
    notDue: {label: "Not due", orderIdx: 10},
    due: {label: "Due", orderIdx: 5},
    soon: {label: "Due soon", orderIdx: 3},
    today: {label: "Due today", orderIdx: 2},
    overdue: {label: "Overdue", orderIdx: 1},
  };

  return {
    prepareExtractData: ({root}) => ({root}),
    extractKey: (card, {root}) => {
      const dueDate = card.$meta.get("dueDate", "loading");
      if (dueDate === "loading") return "notDue";
      if (!dueDate) return "notDue";
      const days = countWorkdaysForAccount({
        account: root.account,
        targetDay: dayToDate(card.dueDate),
      });
      const getKey = () => {
        if (days < 0) return "overdue";
        if (days === 0) return "today";
        if (days < 5) return "soon";
        return "due";
      };
      return getKey();
    },
    extractSecondarySortValue: (c) => {
      const dueDate = c.$meta.get("dueDate", null);
      return dueDate && dayToDateStr(dueDate);
    },
    getLabel: (key, {onLight}) => <MyText onLight={onLight}>{dueKeys[key].label || key}</MyText>,
    toSortKey: (key) => dueKeys[key].orderIdx,
    secondaryOrderProps: [{prop: "dueDate", isReversed: false}],
  };
};

const Container = ({mode, children}) => {
  if (mode === "dropOverlay!!") {
    return (
      <Col align="end" sp="4px">
        {children}
      </Col>
    );
  } else {
    return (
      <Row align="baseline" sp="12px">
        {children}
      </Row>
    );
  }
};

const getStatus = () => {
  const validDragSources = new Set([
    "started",
    "done",
    "archived",
    "snoozing",
    "assigned",
    "unassigned",
  ]);
  const onDropToStatus = {
    started: {label: "to start card", updateFn: (cardIds) => performStartAction({cardIds})},
    done: {updateData: {status: "done"}, label: "to mark card as done"},
    archived: {label: "to archive card", updateFn: (cardIds) => performBulkArchiveAction(cardIds)},
    assigned: {
      updateData: {status: "not_started", visibility: "default"},
      label: "to mark card as not started",
    },
    unassigned: {
      updateData: {status: "not_started", visibility: "default", assigneeId: null},
      label: "to set card as unsassigned",
    },
    doc: {
      updateData: {isDoc: true},
      label: "to turn into doc card",
    },
  };

  const cannotDropToStatus = {
    blocked: "You have to block a card in the card detail view",
    review: "You have to start a review in the card detail view",
    deleted: "You can't delete a card by dropping.",
    assigned: "Don't know who should be assigned!",
    snoozing: "Can't drop here. This state is being set automatically.",
  };

  const cannotDropLabel = (key, card) => {
    const currStatus = getStatusForCard(card);
    const isValidSourceStatus = validDragSources.has(currStatus);
    if (!isValidSourceStatus) {
      switch (currStatus) {
        case "blocked":
          return "You have to unblock a card before changing the status";
        case "review":
          return "You have to close a review before changing the status";
        default:
          return "Can't drag this card";
      }
    }
    const reason = cannotDropToStatus[key];
    if (reason) return reason;
    if (key === "assigned" && !card.assignee) return "Can't drop card here";
    if ((key === "done" || key === "archived") && !hasPermissionToGuardCard(api.getRoot(), card)) {
      return "Only allowed for Guardians.";
    }
    return null;
  };

  const statusIcons = {
    light: {doc: <DSIconDoc style={{color: xcolors.gray500}} size={16} />},
    dark: {doc: <DSIconDoc style={{color: xcolors.gray300}} size={16} />},
  };

  return {
    extractKey: (c) => getStatusForCard(c),
    getLabel: (key, {onLight}) => (
      <XRow align="center" sp={1}>
        {statusIcons[onLight ? "light" : "dark"][key] || null}
        <MyText onLight={onLight}>{statusToLabel[key] || key}</MyText>
      </XRow>
    ),
    toSortKey: (key) => statusSorter(key),
    onDrop: (cards, orderKey) => {
      const validCards = cards.filter((card) => {
        return (
          onDropToStatus[orderKey] &&
          validDragSources.has(getStatusForCard(card)) &&
          (orderKey !== "assigned" || card.assignee)
        );
      });
      if (validCards.length) {
        const {updateData, updateFn} = onDropToStatus[orderKey];
        if (updateFn) {
          return updateFn(validCards.map((c) => c.id));
        } else {
          return api.mutate.cards.bulkUpdate({
            ids: validCards.map((c) => c.id),
            isDoc: false,
            ...updateData,
          });
        }
      } else {
        return null;
      }
    },
    allKeys: () => Object.keys(onDropToStatus),
    cannotDrop: (key, card) => Boolean(cannotDropLabel(key, card)),
    dropLabel: (key) => `Drop ${onDropToStatus[key]?.label || key}.`,
    cannotDropLabel: (key, card) => cannotDropLabel(key, card),
    getLabelSearchFilter: (key) =>
      key === "hero"
        ? {category: "card", value: {type: "isHero"}}
        : {category: "status", value: key},
  };
};

const getHeroCard = () => ({
  prepareExtractData: ({root}) => ({root}),
  extractKey: (card) => {
    const parentCard = getParentCard(card);
    if (parentCard) {
      const childIds = parentCard.$meta.get("childCards", [], {forceRelIds: true}) || [];
      const myId = card.$meta.get("cardId", null);
      const myIdx = myId ? childIds.indexOf(myId) : -1;
      return {idx: myIdx === -1 ? 999 : myIdx + 1, card: parentCard};
    }
    if (hasChildCards(card)) return {idx: 0, card};
    return null;
  },
  keyToScalar: (key) => (key ? key.card.$meta.get("cardId", null) : null),
  getLabel: (key, {onLight}) => <MyText onLight={onLight}>{key ? key.card.title : "None"}</MyText>,
  toSortKey: (key) => (key ? `a${key.card.title.toLowerCase()}` : "b"),
  extractSecondarySortValue: (c, prepared, key) => (key ? key.idx : null),
  secondaryOrderProps: [{prop: "heroCard", isReversed: false}],
  getQuickCreateProps: (key) => ({parentCardId: key?.card.id ?? null}),

  cannotDrop: (orderKey, card) => {
    if (hasChildCards(card)) return true;
    if (!orderKey) return false;
    const {canDrop} = checkDropCards(orderKey.card, [{cardId: card.id, getCard: () => card}]);
    return !canDrop;
  },
  dropLabel: (key) => (key ? "Add as sub card" : "Remove from hero card"),
  cannotDropLabel: (key, card) => {
    if (hasChildCards(card)) return "Can't drop hero card here";
    const {msg} = checkDropCards(key.card, [{cardId: card.id, getCard: () => card}]);
    return msg;
  },
  onDrop: (cards, orderKey) => {
    if (!orderKey) {
      return api.mutate.cards.bulkUpdate({
        ids: cards.map((c) => c.id),
        parentCardId: null,
      });
    } else {
      const childIds = orderKey.card.$meta.get("childCards", [], {forceRelIds: true}) || [];
      return api.mutate.cards.update({
        id: orderKey.card.id,
        childCards: [...childIds, ...cards.map((c) => c.id)],
      });
    }
  },
  allKeys: ({cards, ...rest}) => {
    const heroCards = new Set();
    for (let card of cards) {
      const parentCard = getParentCard(card);
      if (parentCard) {
        heroCards.add(parentCard);
      } else if (hasChildCards(card)) {
        heroCards.add(card);
      }
    }
    const list = [...heroCards];
    list.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1));
    return [...list.map((c) => ({card: c, idx: 0})), null];
  },
  noCompact: true,
});

const tagInfo = (extractFn) => ({
  prepareExtractData: ({projects, root: {loggedInUser, account}}) => {
    return extractFn(loggedInUser, projects, account).reduce((m, k) => {
      m[k.tagObject.tag.toLowerCase()] = k;
      return m;
    }, {});
  },
  keyToScalar: (key) =>
    key === "Not tagged"
      ? key
      : key.type === "project"
        ? `prjct-${key.tagObject.tag}`
        : `prsnl-${key.tagObject.tag}`,
  extractKey: (c, prepared) => {
    const tags = c.tags.map((t) => prepared[t.toLowerCase()]).filter((t) => t);
    return tags.length > 0 ? tags : "Not tagged";
  },
  getLabel: (key, {onLight}) => (
    <MyText onLight={onLight}>
      {key === "Not tagged" ? (
        key
      ) : key.type === "project" ? (
        <ProjectTag tagObject={key.tagObject} onDark={!onLight} />
      ) : (
        <PersonalTag tag={key.tagObject.tag} onDark={!onLight} />
      )}
    </MyText>
  ),
  toSortKey: (key) => (key === "Not tagged" ? "ü" : key.tagObject.tag.toLowerCase()),

  getQuickCreateProps: (key) => {
    if (key === "Not tagged") return {masterTags: []};
    if (key.type === "project") return {masterTags: [key.tagObject.tag]};
    return null;
  },

  onDrop: (cards, orderKey) => {
    if (orderKey.type === "project") {
      return api.mutate.cards.bulkUpdate({
        addMasterTag: orderKey.tagObject.tag,
        ids: cards.map((c) => c.id),
      });
    } else {
      return Promise.all(
        cards.map((card) =>
          waitForResultPromise(() => api.getModel({modelName: "card", id: card.id}).content).then(
            (content) => {
              let newContent;
              // if it ends with a tag, just append after a space, else add newline
              const m = content.match(/(#[A-Za-z0-9][\w\-.]*)\s*$/m);
              if (m) {
                newContent = `${content.slice(0, m.index + m[1].length)} #${
                  orderKey.tagObject.tag
                }`;
              } else {
                newContent = `${content.trim()}\n\n#${orderKey.tagObject.tag}`;
              }
              return api.mutate.cards.update({content: newContent, id: card.id});
            }
          )
        )
      );
    }
  },
  cannotDrop: (orderKey, card) =>
    orderKey === "Not tagged" ||
    card.tags.some((t) => t.toLowerCase() === orderKey.tagObject.tag.toLowerCase()),
  cannotDropLabel: (key) =>
    key === "Not tagged" ? "Can't remove all tags via drag'n'drop" : "Card has this tag already",
  dropLabel: (key) =>
    `Drop to add ${
      key.type === "project"
        ? `project tag #${key.tagObject.tag}`
        : `personal tag #${key.tagObject.tag}`
    }.`,
  allKeys: ({projects, root: {loggedInUser, account}}) => {
    const list = extractFn(loggedInUser, projects, account);
    const asMap = new Map(list.map((k) => [k.tagObject.tag.toLowerCase(), k]));
    return [...asMap.values()];
  },
  multiKey: true,
  getLabelSearchFilter: (key) => {
    if (key === "Not tagged") return null;
    return {
      category: key.type === "project" ? "prjct-tags" : "prsnl-tags",
      value: key.tagObject.tag,
    };
  },
});

const PrioLabel = ({priority, root, mode}) => {
  const {label, Icon} = usePriorityInfo({priority, root});
  if (!priority) return "No priority";
  return mode === "subCards" ? (
    <>
      {label} <Icon size={16} inline />
    </>
  ) : (
    <>
      <Icon size={16} inline /> {label}
    </>
  );
};

const orderInfo = {
  priority: {
    extractKey: (c) => (c.isDoc ? "doc" : c.priority),
    getLabel: (key, {onLight, mode, root}) =>
      key === "doc" ? (
        <XRow align="center" sp={1}>
          {mode !== "subCards" && (
            <DSIconDoc style={{color: onLight ? xcolors.gray500 : xcolors.gray300}} size={16} />
          )}
          <MyText onLight={onLight}>Doc Card</MyText>
          {mode === "subCards" && (
            <DSIconDoc style={{color: onLight ? xcolors.gray500 : xcolors.gray300}} size={16} />
          )}
        </XRow>
      ) : (
        <MyText onLight={onLight}>
          <PrioLabel priority={key} root={root} mode={mode} />
        </MyText>
      ),
    toSortKey: (key) => key || "d",
    getQuickCreateProps: (key) =>
      key === "doc" ? {isDoc: true, priority: null} : {priority: key, isDoc: false},
    onDrop: (cards, orderKey) => {
      if (orderKey === "doc") {
        return api.mutate.cards.bulkUpdate({ids: cards.map((c) => c.id), isDoc: true});
      } else {
        return api.mutate.cards.bulkUpdate({
          ids: cards.map((c) => c.id),
          priority: orderKey,
          isDoc: false,
        });
      }
    },
    allKeys: () => ["a", "b", "c", null],
    dropLabel: (key, label) =>
      key === "doc" ? (
        "Drop to set as Doc card"
      ) : key ? (
        <Row sp="4px" align="center">
          <div>Drop to set to</div>
          {label}
        </Row>
      ) : (
        "Drop to set no priority"
      ),
    getLabelSearchFilter: (key) =>
      key === "doc"
        ? {category: "card", value: {type: "isDoc"}}
        : {category: "priority", value: {priority: key, cmp: "eq"}},
  },
  effort: {
    prepareExtractData: ({root}) => ({account: root.account}),
    extractKey: (c, {account}) => (c.isDoc ? "doc" : getShownEffort(c, account)),
    getLabel: (key, {onLight}) =>
      key === "doc" ? (
        <Row align="center" sp="8px">
          <DSIconDoc style={{color: onLight ? xcolors.gray500 : xcolors.gray300}} size={16} />
          <MyText onLight={onLight}>Doc Card</MyText>
        </Row>
      ) : (
        <Row align="center" sp="8px">
          <DSIconEffort style={{color: onLight ? xcolors.gray500 : xcolors.gray300}} size={16} />
          <MyText onLight={onLight}>{key === null ? "No effort" : key}</MyText>
        </Row>
      ),
    toSortKey: (key) => (key === null && -1) || key,
    getQuickCreateProps: (key) =>
      key === "doc" ? {isDoc: true, effort: null} : {effort: key, isDoc: false},
    onDrop: (cards, orderKey) => {
      if (orderKey === "doc") {
        return api.mutate.cards.bulkUpdate({ids: cards.map((c) => c.id), isDoc: true});
      } else {
        return api.mutate.cards.bulkUpdate({
          ids: cards.map((c) => c.id),
          effort: orderKey,
          isDoc: false,
        });
      }
    },
    allKeys: ({root}) => root.account.effortScale,
    dropLabel: (key) =>
      key === "doc" ? (
        "Drop to set as Doc card"
      ) : (
        <div>Drop to set {key !== null ? ` effort to ${key}` : " no effort"}.</div>
      ),
    cannotDrop: (orderKey, card) => card.childCards.length > 0,
    cannotDropLabel: () => "Can't assign effort to Hero cards",
    getLabelSearchFilter: (key) =>
      key === "doc"
        ? {category: "card", value: {type: "isDoc"}}
        : {category: "effort", value: {effort: key, cmp: "eq"}},
  },
  assignee: {
    extractKey: (c) => c.$meta.get("assignee", null),
    getLabel: (key, {onLight}) => (
      <XRow align="center">
        {key ? (
          <>
            <Avatar user={key} style={{margin: "0 5px 2px"}} size={16} />
            <MyText onLight={onLight} noOverflow>
              {key.name}
            </MyText>
          </>
        ) : (
          <MyText onLight={onLight}>No owner</MyText>
        )}
      </XRow>
    ),
    toSortKey: (key, root) => key && (key === root.loggedInUser ? "" : key.name.toLowerCase()),
    keyToScalar: (key) => key && key.id,
    getQuickCreateProps: (key) => ({assignee: key?.id ?? null}),
    onDrop: (cards, orderKey) =>
      api.mutate.cards.bulkUpdate({
        ids: cards.map((c) => c.id),
        assigneeId: orderKey && orderKey.id,
      }),
    allKeys: ({projects, root}) => [...projectsUsers(root.account, projects), null],
    cannotDrop: (orderKey, card) => {
      if (!orderKey) return false; // can unassign all cards
      const root = api.getRoot();
      const isMyLane = orderKey.id === root.loggedInUser?.id;
      if (card.deck) {
        if (isMyLane) return false; // can assign all cards to me

        const pus = projectsUsers(root.account, [card.deck.project], {allowObservers: false});
        return !pus.some((p) => p.id === orderKey.id);
      } else {
        return !isMyLane;
      }
    },
    cannotDropLabel: (orderKey, firstCard) => {
      if (!firstCard.deck) return "Can't assign private cards to others";
      return "User does not have access to that card";
    },
    dropLabel: (key) =>
      key ? (
        <XRow sp={0}>
          <div>Drop to assign to</div>
          <Avatar user={key} key="avatar" style={{margin: "0 5px 2px"}} size={16} />
          <div>{key.name}</div>
        </XRow>
      ) : (
        "Drop to unassign"
      ),
    getLabelSearchFilter: (key) => ({category: "cardOwner", value: key && key.id}),
  },
  status: getStatus(),
  lastEdit: {
    extractKey: (c) => {
      const lastEntry = c.$meta.first("resolvableEntries", {$order: "-lastChangedAt"});
      const lastUpdated = Math.max(
        lastEntry && lastEntry.lastChangedAt.getTime(),
        c.lastUpdatedAt.getTime()
      );
      return dateToKey(lastUpdated);
    },
    extractSecondarySortValue: (c) => {
      const lastEntry = c.$meta.first("resolvableEntries", {$order: "-lastChangedAt"});
      return -Math.max(lastEntry && lastEntry.lastChangedAt.getTime(), c.lastUpdatedAt.getTime());
    },
    keyToScalar: (key) => key && key.diff,
    getLabel: (key, {onLight}) => (
      <XRow align="center">
        <MyText onLight={onLight}>{key.label}</MyText>
      </XRow>
    ),
    toSortKey: (key) => key.diff,
    secondaryOrderProps: [{prop: "lastEdit", isReversed: false}],
  },
  creationDate: {
    extractKey: (c) => dateToKey(c.createdAt.getTime()),
    extractSecondarySortValue: (c) => -c.createdAt.getTime(),
    keyToScalar: (key) => key && key.diff,
    getLabel: (key, {onLight}) => (
      <XRow align="center">
        <MyText onLight={onLight}>{key.label}</MyText>
      </XRow>
    ),
    toSortKey: (key) => key.diff,
    secondaryOrderProps: [{prop: "creationDate", isReversed: false}],
  },
  tags: tagInfo((u, ps, a) => tagObjectsForUser(u, ps, a)),
  tagsPersonal: tagInfo((u, p, a) =>
    personalTags(u, a).map((t) => ({type: "personal", tagObject: t}))
  ),
  tagsProject: tagInfo((u, ps) => projectsTags(ps).map((t) => ({type: "project", tagObject: t}))),
  deck: {
    extractKey: (c) => c.$meta.get("deck", null),
    getLabel: (key, {arenaCtx, mode, root, onLight} = {}) => {
      if (arenaCtx.type === "deck" && mode !== "dropZone" && mode !== "subCards") {
        return <MyText onLight={onLight}>Compact</MyText>;
      }

      const showProjects = mode !== "subCards" && getSelectedProjects(root).length > 2;
      return key ? (
        <Container mode={mode}>
          <MiniDeck
            deck={key}
            maxChars={mode !== "cardPanel" ? 25 : 60}
            nonBold
            onLight={onLight}
          />
          {showProjects && (
            <XText size={0} color={onLight ? "gray500" : "gray400"}>
              in {key.project.name}
            </XText>
          )}
        </Container>
      ) : (
        <XText
          size={1}
          color={onLight ? "gray700" : "gray100"}
          align={mode === "dropZone" && "right"}
        >
          No deck
        </XText>
      );
    },
    toSortKey: (key) => key && key.title.toLowerCase(),
    getQuickCreateProps: (key) => ({deckId: key?.id ?? null}),
    keyToScalar: (key) => key && key.id,
    onDrop: (cards, orderKey) => {
      if (orderKey) {
        moveCardsToDeck(
          cards.map((c) => c.id),
          orderKey.id
        );
      }
    },
    allKeys: ({projects, root}) => [
      ...projectsDecks({
        root,
        projectIds: projects.map((p) => p.$meta.get("id", null)).filter(Boolean),
      }),
      null,
    ],
    dropLabel: (key, label) =>
      key ? (
        <XRow sp={1} align="baseline">
          <div>Drop to move to</div>
          <div>{label}</div>.
        </XRow>
      ) : (
        "Drop to remove from deck."
      ),
    cannotDrop: (key, card) => {
      if (!key) return true;
      return !hasPermissionToGuardCard(api.getRoot(), card);
    },
    cannotDropLabel: (key, card) => {
      if (!key) return "Codecks does not allow to turn non-private cards to prviate ones.";
      if (!hasPermissionToGuardCard(api.getRoot(), card)) {
        return "Only Guardians may change decks for this card";
      }
      return "Cannot Drop";
    },
    getLabelSearchFilter: (key) => ({category: "deck", value: key && key.id}),
  },
  milestone: {
    extractKey: (c) => c.$meta.get("milestone", null),
    getLabel: (key, {arenaCtx, mode, root, onLight}) => {
      if (arenaCtx.type === "milestone" && mode !== "dropZone" && mode !== "subCards") {
        return <MyText onLight={onLight}>Compact</MyText>;
      }
      if (key) {
        const date = key.date;
        const todayMidnight = new Date(new Date().setHours(0, 0, 0, 0));
        const msDate = msToDate({date});
        const diffInDays = Math.round(
          (msDate.getTime() - todayMidnight.getTime()) / (24 * 3600 * 1000)
        );
        const workdays = countWorkdaysForAccount({
          account: root.account,
          todayMidnight,
          targetDay: msDate,
        });

        if (mode === "subCards") {
          return (
            <Row sp="4px" align="center">
              <ThemedMilestoneIcon
                size={16}
                theme={setMsThemeColor(key)}
                milestoneState={getMilestoneState(root.account, key)}
              />
              <XText size={1} color={onLight ? "gray700" : "gray100"} preset="bold" noOverflow>
                {key.name}
              </XText>
              <MyText onLight={onLight}>{workdays}d</MyText>
            </Row>
          );
        } else {
          let dateLabel = null;
          if (diffInDays === 0) {
            dateLabel = "today";
          } else if (diffInDays > 0 && diffInDays < 31) {
            dateLabel = `in ${workdays} workday${workdays === 1 ? "" : "s"}`;
          } else {
            dateLabel = `on ${date.day < 10 ? "0" : ""}${date.day} ${monthNames[date.month - 1]} ${
              date.year
            }`;
          }
          return (
            <Container mode={mode}>
              <Row sp="4px" align="baseline">
                <ThemedMilestoneIcon
                  size={16}
                  theme={setMsThemeColor(key)}
                  inline
                  milestoneState={getMilestoneState(root.account, key)}
                />
                <XText size={1} color={onLight ? "gray700" : "gray100"} preset="bold">
                  {key.name}
                </XText>
              </Row>
              <MyText onLight={onLight}>{dateLabel}</MyText>
            </Container>
          );
        }
      } else {
        return <MyText onLight={onLight}>No milestone</MyText>;
      }
    },
    toSortKey: (key) => (key === null ? "z" : dayToDateStr(key.date)),
    getQuickCreateProps: (key) => ({milestoneId: key?.id ?? null}),
    keyToScalar: (key) => key && key.id,
    onDrop: (cards, orderKey) => {
      const cardIds = cards.map((c) => c.id);
      isMilestoneValidTargetForCards({milestoneId: orderKey && orderKey.id, cardIds}).then(
        (res) => {
          if (res.ok) {
            return api.mutate.cards.bulkUpdate({
              ids: cardIds,
              milestoneId: orderKey && orderKey.id,
            });
          } else {
            return messenger.send(
              `Milestone can't receive cards from ${joinAnd(res.unsupportedProjectNames)} project`,
              {type: "error"}
            );
          }
        }
      );
    },
    allKeys: ({root, projects}) => [...getFutureMilestonesByProject(root.account, projects), null],
    dropLabel: (key, label) =>
      key ? (
        <Row sp="4px" align="baseline">
          <div>Drop to set</div>
          {label}
        </Row>
      ) : (
        "Drop to remove milestone."
      ),
    getLabelSearchFilter: (key) => ({category: "milestone", value: key && key.id}),
  },
  sprint: {
    extractKey: (c) => c.$meta.get("sprint", null),
    getLabel: (key, {arenaCtx, mode, root, onLight}) => {
      if (arenaCtx.type === "sprint" && mode !== "dropZone" && mode !== "subCards") {
        return <MyText onLight={onLight}>Compact</MyText>;
      }
      if (key) {
        switch (mode) {
          case "subCards":
            return (
              <Row sp="4px" align="baseline">
                <ThemedSprintIcon
                  theme={setMsThemeColor(key.sprintConfig)}
                  size={16}
                  inline
                  sprintState={getSprintState(key)}
                />
                <XText size={1} color={onLight ? "gray700" : "gray100"} noOverflow preset="bold">
                  {sprintLabel(key)}
                </XText>
                <XText size={1} color={onLight ? "gray700" : "gray100"}>
                  {key.sprintConfig.name}
                </XText>
                <MyText onLight={onLight} className={css({textWrap: "nowrap"})}>
                  {superShortDate(dayToDate(key.endDate))}
                </MyText>
              </Row>
            );
          case "dropOverlay":
            return (
              <XText size={1} color={onLight ? "gray700" : "gray100"} preset="bold">
                {sprintLabel(key)}
              </XText>
            );
          default:
            return (
              <Row sp="8px" align="baseline">
                <ThemedSprintIcon
                  theme={setMsThemeColor(key.sprintConfig)}
                  size={16}
                  inline
                  sprintState={getSprintState(key)}
                />
                <XText size={1} color={onLight ? "gray700" : "gray100"} noOverflow preset="bold">
                  {sprintLabel(key)}
                </XText>
                <XText size={1} color={onLight ? "gray700" : "gray100"}>
                  {key.sprintConfig.name}
                </XText>
                <MyText onLight={onLight}>
                  {superShortDate(dayToDate(key.startDate))} -{" "}
                  {superShortDate(dayToDate(key.endDate))}
                </MyText>
              </Row>
            );
        }
      } else {
        return <MyText onLight={onLight}>Not in Run</MyText>;
      }
    },
    toSortKey: (key) => (key === null ? "z" : dayToDateStr(key.endDate)),
    getQuickCreateProps: (key) => ({sprintId: key?.id ?? null}),
    keyToScalar: (key) => key && key.id,
    onDrop: (cards, orderKey) => {
      const cardIds = cards.map((c) => c.id);
      isSprintValidTargetForCards({sprintId: orderKey && orderKey.id, cardIds}).then((res) => {
        if (res.ok) {
          return api.mutate.cards.bulkUpdate({
            ids: cardIds,
            sprintId: orderKey && orderKey.id,
          });
        } else {
          return messenger.send(
            `Run can't receive cards from ${joinAnd(res.unsupportedProjectNames)} project`,
            {type: "error"}
          );
        }
      });
    },
    allKeys: ({root, projects}) => {
      const getConstraints = () => {
        const projectIds = projects.map((p) => p.$meta.get("id", null)).filter(Boolean);
        if (!projectIds.length) return null;
        return {sprintConfig: {sprintProjects: {projectId: projectIds}}};
      };
      return [
        ...getActiveAndFutureSprints({account: root.account, constraints: getConstraints()}),
        null,
      ];
    },
    dropLabel: (key, label) =>
      key ? (
        <Row sp="8px" align="baseline">
          <div>Drop to set</div>
          {label}
        </Row>
      ) : (
        "Drop to remove Run."
      ),
    getLabelSearchFilter: (key) => ({category: "sprint", value: key && key.id}),
  },
  project: {
    prepareExtractData: ({root}) => getProjectOrderIdx(root),
    extractKey: (c, projectOrderIdx) => {
      if (!c.deck) return {project: null, idx: null};
      const project = c.deck.$meta.get("project", null);
      return {project, idx: project && (projectOrderIdx[project.id] || project.name.toLowerCase())};
    },
    getLabel: ({project}, {mode, onLight} = {}) => {
      return (
        <XRow align="center" style={mode === "dropZone" ? {textAlign: "right"} : null} sp={0}>
          {project ? (
            <>
              <XCol
                as={CdxCropImgByFile}
                width={18}
                height={18}
                file={project.coverFile}
                elevation={1}
                noShrink
                rounded="sm"
                noOverflow
                border={onLight ? "gray100" : "gray500"}
                bg={onLight ? "gray300" : "gray700"}
                className={cx(
                  styles.deckCover.default,
                  mode === "dropZone" && styles.deckCover.right
                )}
                fallbackClassName={
                  onLight ? uiClasses.backgroundColor.gray400 : uiClasses.backgroundColor.gray600
                }
              />
              <MyText onLight={onLight}>{project.name}</MyText>
            </>
          ) : (
            <MyText onLight={onLight}>No Project</MyText>
          )}
        </XRow>
      );
    },
    toSortKey: ({idx}) => idx,
    keyToScalar: ({project}) => project && project.id,
    allKeys: ({projects}) => [...projects, null],
    getLabelSearchFilter: ({project}) => ({category: "project", value: project && project.id}),
  },
  changes: {
    prepareExtractData: ({notifications, root}) => ({
      notifications,
      userId: root.loggedInUser?.id || null,
    }),
    extractKey: (c, {notifications, userId}) => {
      const diffNoti = notifications.perCardDiffs[c.id];
      if (!diffNoti) return "none";
      const keys = [];
      const types = cardDiffToTypes(diffNoti.changes, userId);
      if (types.has("newCard")) {
        keys.push("newCard");
      } else {
        keys.push(...types);
      }
      const resNotis = notifications.perCardResolvables[c.id];
      if (resNotis && resNotis.some((rn) => !rn.isParticipating)) {
        keys.push("nonParticipatingResolvable");
      }
      return keys;
    },
    getLabel: (key, {onLight}) => (
      <MyText onLight={onLight}>{changeTypeToLabel[key] || key}</MyText>
    ),
    toSortKey: (key) => -changeTypeToPriority[key],
    multiKey: true,
  },
  upvotes: {
    extractKey: (c) => {
      const meta = c.$meta.get("meta", null);
      if (!meta) return null;
      const upvotes = getTotalUpvotes(c);
      if (!upvotes) return null;
      return Math.floor((upvotes || 0) / 5) * 5;
    },
    extractSecondarySortValue: (c) => {
      const meta = c.$meta.get("meta", null);
      return -((meta && getTotalUpvotes(c)) || 0);
    },
    getLabel: (key, {onLight}) => (
      <MyText onLight={onLight}>
        {key === null ? (
          "No upvotes"
        ) : (
          <>
            <b>{key === 0 ? 1 : key}+</b> upvotes
          </>
        )}
      </MyText>
    ),
    toSortKey: (key) => (key === null ? 1 : -key),
    secondaryOrderProps: [{prop: "upvotes", isReversed: false}],
  },
  title: {
    extractKey: (c) => {
      const title = c.$meta.get("title", null);
      if (!title) return "none";
      return Array.from(title)[0].toLowerCase();
    },
    extractSecondarySortValue: (c) => c.title.toLowerCase(),
    getLabel: (key, {onLight}) => <MyText onLight={onLight}>{key.toUpperCase()}</MyText>,
    toSortKey: (key) => key,
    secondaryOrderProps: [{prop: "title", isReversed: false}],
  },
  // only used for secondary sorting
  accountSeq: {
    extractKey: (c) => c.$meta.get("accountSeq", null),
    toSortKey: (key) => key,
  },
  timeTracked: getTimeTracked(),
  dueDate: getDueDate(),
  heroCard: getHeroCard(),
  beastLevel: {
    prepareExtractData: ({root}) => ({root}),
    extractKey: (card, {root}) => {
      const level = getBeastLevel(root.account, card);
      if (level) return level;
      return getIsNewUntil(root.account, card) ? "new" : 0;
    },
    getLabel: (key, {onLight}) =>
      key === "new" ? (
        <AddedDuringSprintPill />
      ) : key ? (
        <BeastPill level={key} />
      ) : (
        <MyText onLight={onLight}>No beast level</MyText>
      ),
    toSortKey: (key) => (key === "new" ? -0.5 : -key),
    secondaryOrderProps: [{prop: "beastLevel", isReversed: false}],
  },
};

export default orderInfo;
