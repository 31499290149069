import {
  useRef,
  useEffect,
  useMemo,
  useState,
  ReactNode,
  MutableRefObject,
  CSSProperties,
  useLayoutEffect,
} from "react";
import {errorToString, MiniEvent} from "@cdx/common";
import {mergeRefs} from "react-merge-refs";
import {CARD_HEIGHT, CARD_WIDTH} from "../../components/Card/card.css";
import {api} from "../../lib/api";
import messenger from "../../lib/messenger";
import {waitForResultPromise} from "../../lib/wait-for-result";
import {useDropZone} from "@codecks/dnd";
import shallowEqual from "shallowequal";
import {getSelectedCardIds} from "../card-selection/useSelectedCards";
import {Card, CardId} from "../../cdx-models/Card";
import {useDSComputeWithDimensions} from "@cdx/ds/hooks/position-hooks";
import {Box, Col, Row, Text} from "@cdx/ds";
import {transitions} from "@cdx/ds/css/decoration.css";
import useSet from "../../lib/hooks/useSet";

const MARGIN = 16;

type DropAnimManager = {
  useMe(
    key: string,
    cardId: CardId,
    shift: "left" | "right" | null
  ): MutableRefObject<HTMLDivElement | null>;
  startAnim: (droppedKeys: CardId[]) => () => void;
};

const createDropAnimManager = (): DropAnimManager => {
  const keyMap = new Map<string, MutableRefObject<HTMLDivElement | null>[]>();
  const idToKeys = new Map<CardId, string[]>();
  return {
    useMe: (key, cardId, shift) => {
      const ref = useRef<HTMLDivElement>(null);
      useLayoutEffect(() => {
        if (shift) {
          let n = ref.current;
          if (n) {
            n.style.transform = `translate3d(${shift === "left" ? "-10px" : "10px"}, 0, 0)`;
            return () => {
              n.style.transform = "";
            };
          }
        }
      }, [shift]);
      useEffect(() => {
        const refs = keyMap.get(key);
        if (refs) {
          refs.push(ref);
        } else {
          keyMap.set(key, [ref]);
        }

        return () => {
          const refs2 = keyMap.get(key);
          if (refs2) {
            if (refs2.length === 1) {
              keyMap.delete(key);
            } else {
              const idx = refs2.indexOf(ref);
              if (idx >= 0) refs2.splice(idx, 1);
            }
          }
        };
      }, [key]);
      useEffect(() => {
        const keys = idToKeys.get(cardId);
        if (keys) {
          keys.push(key);
        } else {
          idToKeys.set(cardId, [key]);
        }

        return () => {
          const keys2 = idToKeys.get(cardId);
          if (keys2) {
            if (keys2.length === 1) {
              idToKeys.delete(cardId);
            } else {
              const idx = keys2.indexOf(key);
              if (idx >= 0) keys2.splice(idx, 1);
            }
          }
        };
      }, [cardId, key]);
      return ref;
    },
    startAnim: (droppedCardIds) => {
      const droppedKeys = new Set(
        droppedCardIds.flatMap((id) => {
          const keys = idToKeys.get(id);
          return keys ? Array.from(keys) : [];
        })
      );
      const posList: {
        ref: MutableRefObject<HTMLDivElement | null>;
        prevPos: DOMRect;
        postPos: DOMRect | null;
        isDropped: boolean;
      }[] = [];
      for (const [key, refs] of keyMap) {
        for (const ref of refs) {
          if (!ref.current) continue;
          posList.push({
            ref,
            prevPos: ref.current.getBoundingClientRect(),
            postPos: null,
            isDropped: droppedKeys.has(key),
          });
        }
      }
      requestAnimationFrame(() => {
        for (const {ref} of posList) {
          if (ref.current) {
            ref.current.style.transition = "none";
            ref.current.style.transform = "";
          }
        }
        for (const el of posList) {
          el.postPos = el.ref.current?.getBoundingClientRect() ?? null;
        }
        const resetPos: Set<MutableRefObject<HTMLDivElement | null>> = new Set();
        for (const {isDropped, ref, prevPos, postPos} of posList) {
          if (!ref.current) continue;
          if (isDropped) {
            ref.current.style.transform = `scale(0.5)`;
            ref.current.style.opacity = `0`;
            resetPos.add(ref);
          } else if (prevPos && postPos) {
            const diffX = prevPos.x - postPos.x;
            const diffY = prevPos.y - postPos.y;
            if (diffX === 0 && diffY === 0) {
              ref.current.style.transition = "";
            } else {
              ref.current.style.transform = `translate3d(${diffX}px, ${diffY}px, 0)`;
              resetPos.add(ref);
            }
          } else {
            ref.current.style.transition = "";
          }
        }
        requestAnimationFrame(() => {
          for (const {isDropped, ref} of posList) {
            if (!resetPos.has(ref)) continue;
            if (ref.current) {
              ref.current.style.transition = ""; // use the default transition within `transitions.transformAndColors`
              if (isDropped) {
                ref.current.style.transform = `scale(1)`;
                ref.current.style.opacity = `1`;
              } else {
                ref.current.style.transform = `translate3d(0, 0, 0)`;
              }
            }
          }
          setTimeout(() => {
            for (const ref of resetPos) {
              if (!ref.current) continue;
              ref.current.style.transform = "";
              ref.current.style.opacity = "";
            }
          }, 150);
        });
      });
      return () => {};
    },
  };
};

const dropAnimManager = createDropAnimManager();

const AnimBox = ({
  children,
  entryKey,
  cardId,
  shift,
}: {
  children: ReactNode;
  entryKey: string;
  cardId: CardId;
  shift: "left" | "right" | null;
}) => {
  const ref = dropAnimManager.useMe(entryKey, cardId, shift);
  return (
    <div className={transitions.transformAndColors} ref={ref}>
      {children}
    </div>
  );
};

export const useExternalCardDrop = () => {
  const dropInfoRef = useRef(null as any as MiniEvent<any>);
  if (!dropInfoRef.current) {
    dropInfoRef.current = new MiniEvent();
  }
  const onDropStart = (data: any) => dropInfoRef.current.emit("drop", data);
  const onDropEnd = () => dropInfoRef.current.emit("end");

  return {externalDropInfo: dropInfoRef, onDropStart, onDropEnd};
};

type DragCtx = {
  onDropItem: (cardIds: CardId[]) => void;
};

type RenderArgs<T> = {
  entry: T;
  draggable: true;
  allowSelection?: boolean;
  isDroppedExternal: boolean;
  cardContainerKey: string;
  style?: CSSProperties;
  dragCtx: DragCtx;
};

type DropItem = {
  id: CardId;
  data: {
    dragCtx: DragCtx;
  };
};

type DragInfo = {
  dragIndex: number;
  currIndex: number;
  col: number;
  row: number;
};

const useDragAndDrop = <T extends any>(props: SortableCardListProps<T>) => {
  const {
    entries,
    getCardIdFromEntry,
    handleItemDrop,
    getFreshItems,
    createOutsideEntry,
    allowSelection,
    getDropErrors,
    verticalMargin = MARGIN,
    onFinishedDrop,
    getKey,
    externalDropInfo,
  } = props;
  const [containerRef, cardsPerRow] = useDSComputeWithDimensions((dims) =>
    Math.max(1, Math.floor(((dims ? dims.width : 800) + MARGIN) / (CARD_WIDTH + MARGIN)))
  );
  const [dragInfo, setDragInfo] = useState<null | DragInfo>(null);
  const [inflightCardIds, setInflightCardIds] = useState<CardId[] | null>(null);
  const [dropError, setDropError] = useState<null | string>(null);
  const [externalDropCardIds, {set: setExternalDropCardIds}] = useSet<CardId>();

  const onDropItemRef = useRef<DragCtx["onDropItem"]>(null as any as DragCtx["onDropItem"]);
  const propRef = useRef(props);
  propRef.current = props;
  if (onDropItemRef.current === null) {
    // will be called if dragged card is dropped in a different list
    // will remove the card from the list and thus animate the other cards filling the gap
    onDropItemRef.current = (cardIds: CardId[]) => {
      const droppedIds = new Set(cardIds);
      const entryIds = propRef.current.entries.map((e) => getCardIdFromEntry(e));
      setInflightCardIds(entryIds.filter((id) => !droppedIds.has(id)));
      return new Promise((res) => setTimeout(res, 100))
        .then(() => waitForResultPromise(() => propRef.current.getFreshItems()))
        .then(() => setInflightCardIds(null));
    };
  }

  useEffect(() => {
    if (externalDropInfo && externalDropInfo.current) {
      return externalDropInfo.current.addListener((type, data) => {
        if (type === "drop" && data && data.cardIds) {
          setExternalDropCardIds(new Set(data.cardIds));
        } else if (type === "end") {
          waitForResultPromise(() => propRef.current.getFreshItems()).then(() => {
            setExternalDropCardIds(new Set());
          });
        }
      });
    }
  }, [externalDropInfo, setExternalDropCardIds]);

  const totalRows = Math.max(1, Math.ceil(entries.length / cardsPerRow));

  const handleDragOver = ({
    position,
    item,
  }: {
    position: null | {x: number; y: number};
    item: DropItem;
  }) => {
    if (!position) {
      setDragInfo(null);
      setDropError(null);
    } else {
      if (getDropErrors) {
        const currDropError = getDropErrors(item, (err) => {
          setDropError(err);
          setDragInfo(null);
        });
        if (currDropError) {
          setDropError(currDropError);
          setDragInfo(null);
          return;
        } else {
          setDropError(null);
        }
      }
      const row = Math.min(
        totalRows - 1,
        Math.max(0, Math.floor((position.y + verticalMargin / 2) / (CARD_HEIGHT + verticalMargin)))
      );
      let lastRow = entries.length % cardsPerRow;
      if (lastRow === 0) lastRow = cardsPerRow;
      const col = Math.min(
        Math.max(0, Math.floor((position.x + (CARD_WIDTH + MARGIN) / 2) / (CARD_WIDTH + MARGIN))),
        row === totalRows - 1 ? lastRow : cardsPerRow
      );

      const targetIndex = row * cardsPerRow + col;
      if (dragInfo === null || dragInfo.dragIndex !== targetIndex) {
        const currItemIdx = entries.findIndex((e) => getCardIdFromEntry(e) === item.id);
        setDragInfo({dragIndex: targetIndex, currIndex: currItemIdx, col, row});
      }
    }
  };

  const innerHandleItemDrop = ({item}: {item: DropItem}) => {
    if (!dragInfo) return;
    const {dragCtx} = item.data;
    const initialOrderedItemsIds = entries.map((e) => getCardIdFromEntry(e));
    let orderedItemsIds = [...initialOrderedItemsIds];
    const selectedCardIds = getSelectedCardIds();
    const dragItemIds =
      allowSelection && selectedCardIds.has(item.id) ? [...selectedCardIds] : [item.id];

    let offsetTargetIdx = 0;
    const draggedCardIds = dragItemIds.map((itemId) => {
      const itemIdx = orderedItemsIds.indexOf(itemId);
      if (itemIdx > -1 && dragInfo.dragIndex > itemIdx) {
        offsetTargetIdx += 1;
      }
      return itemIdx === -1 ? itemId : orderedItemsIds.splice(itemIdx, 1)[0];
    });

    const targetIndex = dragInfo.dragIndex - offsetTargetIdx;
    orderedItemsIds.splice(targetIndex, 0, ...draggedCardIds);
    const sameOrder = shallowEqual(orderedItemsIds, initialOrderedItemsIds);
    if (sameOrder) {
      setDragInfo(null);
      return;
    }
    if (dragCtx?.onDropItem && dragCtx?.onDropItem !== onDropItemRef.current) {
      dragCtx.onDropItem(dragItemIds);
    }
    const doneAnimCb = dropAnimManager.startAnim(dragItemIds);
    setInflightCardIds(orderedItemsIds);
    setDragInfo(null);
    return handleItemDrop(orderedItemsIds, {draggedCardIds: dragItemIds, targetIndex})
      .then(
        () => {},
        (e) => {
          console.error(e);
          messenger.send(errorToString(e), {type: "error"});
        }
      )
      .then(() => waitForResultPromise(() => getFreshItems()))
      .then(() => setInflightCardIds(null))
      .finally(() => {
        doneAnimCb();
        if (onFinishedDrop) onFinishedDrop();
      });
  };

  const {ref: dropRef} = useDropZone({
    type: "CARD",
    onDrop: innerHandleItemDrop,
    onDragOver: handleDragOver,
  });

  const getEntries = () => {
    if (inflightCardIds) {
      const entriesByCardId = new Map<CardId, T>(entries.map((e) => [getCardIdFromEntry(e), e]));
      return inflightCardIds.map(
        (id) => entriesByCardId.get(id) || createOutsideEntry(api.getModel({modelName: "card", id}))
      );
    } else {
      return entries;
    }
  };

  // const keysAndDataIn = orderedItems.map((entry, idx) => ({
  const keysAndDataIn = getEntries().map((entry, idx) => ({
    key: getKey(entry, idx),
    data: {
      entry,
      idx,
      isDragged: dragInfo?.currIndex === idx,
      isDropped: externalDropCardIds.size > 0 && externalDropCardIds.has(getCardIdFromEntry(entry)),
      cardId: getCardIdFromEntry(entry),
    },
  }));

  const dropErrorElement = dropError && (
    <Col
      absolute
      inset="0"
      align="center"
      justify="center"
      pa="16px"
      colorTheme="warn25"
      bg="foreground"
      opacity={0.5}
      zIndex={2}
    >
      <Text type="label12" color="primary">
        {dropError}
      </Text>
    </Col>
  );

  return {
    keysAndDataIn,
    dropRef,
    containerRef,
    dropErrorElement,
    dragInfo,
    cardsPerRow,
    dragCtx: {onDropItem: onDropItemRef.current},
  };
};

const TargetIndicator = ({dragInfo: {row, col}}: {dragInfo: DragInfo}) => {
  const left = col * (CARD_WIDTH + MARGIN) - MARGIN / 2 - 1;
  const top = row * (CARD_HEIGHT + MARGIN);
  return (
    <Box
      absolute
      height="cardHeight"
      maxHeight="100%"
      className={transitions.transforms}
      borderWidth={0}
      borderRightWidth={1}
      borderColor="default"
      style={{zIndex: -1, transform: `translate(${left}px, ${top}px)`}}
    />
  );
};

type SortableCardListProps<T> = {
  getCardIdFromEntry: (entry: T) => CardId;
  entries: T[];
  handleItemDrop: (
    entries: CardId[],
    data: {draggedCardIds: CardId[]; targetIndex: number}
  ) => Promise<any>;
  getFreshItems: () => Promise<T[]>;
  createOutsideEntry: (card: Card) => T;
  getKey: (entry: T, idx: number) => string;
  renderEntry: (opts: RenderArgs<T>) => ReactNode;
  renderPostfix?: (dataIn: {data: {entry: T}; key: string}[]) => ReactNode;
  parentFlipKey: string;
  isOrderLoaded: boolean;
  cardContainerKey: string;
  navigation?: any;
  getDropErrors?: (entry: DropItem, cb: (err: any) => void) => any;
  verticalMargin?: number;
  onFinishedDrop?: () => void;
  externalDropInfo?: MutableRefObject<MiniEvent<any>>;
  allowSelection?: boolean;
};
const SortableCardList = <T extends any, TEntryData>(
  props: SortableCardListProps<T> & {entryData?: TEntryData}
) => {
  const {isOrderLoaded, renderEntry, renderPostfix, cardContainerKey, allowSelection} = props;

  const {keysAndDataIn, dropRef, containerRef, dropErrorElement, dragInfo, cardsPerRow, dragCtx} =
    useDragAndDrop(props);

  const getTargetIndex = () => {
    if (!dragInfo) return null;
    const {currIndex, dragIndex} = dragInfo;
    if (currIndex > -1 && (currIndex === dragIndex || currIndex === dragIndex - 1)) return null;
    return dragIndex;
  };

  const targetIndex = getTargetIndex();
  const sameRow = (idx: number, targetRow: number) => Math.floor(idx / cardsPerRow) === targetRow;

  const refs = useMemo(() => mergeRefs([dropRef, containerRef]), [containerRef, dropRef]);
  return (
    <Row opacity={isOrderLoaded ? 1 : 0} wrap sp="16px" relative zIndex={1} ref={refs}>
      {keysAndDataIn.map(({data: {entry, idx, isDragged, isDropped, cardId}, key}) => (
        <AnimBox
          key={key}
          entryKey={key}
          cardId={cardId}
          shift={
            dragInfo && targetIndex !== null && sameRow(idx, dragInfo.row)
              ? idx < targetIndex!
                ? "left"
                : "right"
              : null
          }
        >
          {renderEntry({
            entry,
            draggable: true,
            allowSelection,
            isDroppedExternal: isDropped,
            cardContainerKey,
            dragCtx,
          })}
        </AnimBox>
      ))}
      {renderPostfix && renderPostfix(keysAndDataIn)}
      {dragInfo && targetIndex !== null && <TargetIndicator dragInfo={dragInfo} />}
      {dropErrorElement}
    </Row>
  );
};

export default SortableCardList;
